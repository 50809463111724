import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styles from './blog-post.module.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

function BlogPostTemplate({ data, pageContext, location }) {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className={styles.blog_post} style={{}}>
        <header>
          <h1>{post.frontmatter.title}</h1>
          <p className={styles.date}>{post.frontmatter.date}</p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
      <nav className={styles.bottom_nav}>
        <ul>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                <span className={`${styles.arrow} ${styles.arrowLeft}`}>←</span>
                <span className={styles.linkText}>
                  <span className={styles.linkHeader}>PREVIOUS</span>
                  {previous.frontmatter.title}
                </span>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                <span className={styles.linkText}>
                  <span className={styles.linkHeader}>NEXT</span>
                  {next.frontmatter.title}
                </span>
                <span className={`${styles.arrow} ${styles.arrowRight}`}>
                  →
                </span>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMM D, YYYY")
        description
      }
    }
  }
`;
